<template>
    <main>
		<HeaderTab 
            :title="$t('notes.infos_note')"
        />

        <div id="content">
            <shutter-panel />
        </div>
	</main>
</template>


<script type="text/javascript">
    import Shutter from '@/mixins/Shutter.js'
    import ShutterNotes from '@/mixins/shutters-manager/Notes.js'

	export default {
		name: "noteListe",
        mixins: [Shutter, ShutterNotes],
        computed: {
            noteId() {
                return this.$route.params.note_id
            }
        },
        mounted() {
            this.setupNotePreview(this.noteId)
        },
		components: {
            HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
			NotePreview: () => import('GroomyRoot/components/ShutterScreens/Notes/NotePreview')
		}
	}
</script>
